import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { LinkButton } from '@builtbypixel/nucleus';
import ImageOrFile from './ImageOrFile';
const EventDocument = ({ file }) => {
    return (
        <Box border="1px" borderColor="gray.200" my="20px" p="15px" w="100%">
            <Flex w="100%" direction="row" justify="space-between" mb="15px">
                <LinkButton to={`/events/edit/${file?.event_id?.id}`}>
                    <Button variant="link" colorScheme="blue" rounded="md">
                        {file?.event_id?.event_code}
                    </Button>
                </LinkButton>
                <Text fontWeight={'bold'}>{file.name}</Text>
            </Flex>

            {/* {file?.document?.map((eachDoc) => ( */}
                <ImageOrFile url={file?.document?.url} />
            {/* ))} */}
        </Box>
    );
};

export default EventDocument;

import React, { useEffect } from 'react';
import { Text, Flex, Box, Stack, Badge, Tooltip, SimpleGrid } from '@chakra-ui/react';

import Table from '../../../components/common/CustomTable';
import LoadingSpinner from '../../../components/LoadingSpinner';
import FormSection from '../../../components/FormSection';
import formatCurrency from '../../../utils/formatCurrency';
import DataView from '../components/DataView';

const TableView = ({ data, loading, setExportData, title, exportData }) => {
    // console.log(data, 'DATA');
    const columns = [
        {
            Header: 'Name',
            accessor: 'course',
        },

        {
            Header: 'No. of Delegates',
            accessor: 'delegate_count',
            Cell: ({ value }) => <>{value ?? 0}</>,
        },
        {
            Header: 'Pass %',
            accessor: 'pass_percentage',
            Cell: ({ value }) => <>{`${Math.round(value)}%`}</>,
        },
        {
            Header: 'Fail %',
            accessor: 'failed_percentage',
            Cell: ({ value }) => <>{`${Math.round(value)}%`}</>,
        },
        {
            Header: 'Unknown %',
            accessor: 'unknown_percentage',
            Cell: ({ value }) => <>{`${Math.round(value)}%`}</>,
        },
        // {
        //     Header: 'Profit/Loss',
        //     accessor: 'profit_loss',
        //     Cell: ({ row }) => (
        //         <Stack>
        //             <Tooltip
        //                 hasArrow
        //                 label={
        //                     <>
        //                         <Text fontSize={12}>
        //                             Revenue: {formatCurrency(row.original.revenue)}
        //                         </Text>
        //                         <Text fontSize={12}>
        //                             Total Cost: £{formatCurrency(row.original.total_cost)}
        //                         </Text>
        //                     </>
        //                 }
        //                 bg="gray.300"
        //                 color="black"
        //             >
        //                 <Badge
        //                     fontSize="12px"
        //                     padding="5px"
        //                     colorScheme={
        //                         row.original.profit_loss.toString()?.includes('-') ? 'red' : 'green'
        //                     }
        //                 >
        //                     {formatCurrency(row.original.profit_loss)}
        //                 </Badge>
        //             </Tooltip>
        //         </Stack>
        //     ),
        // },
    ];

    useEffect(() => {
        setExportData({
            ...exportData,
            [title.replaceAll(' ', '_')]: { data: data?.data ? data?.data : [], columns },
        });
    }, [data]);

    return (
        <>
            {loading || !data ? (
                <LoadingSpinner />
            ) : data.length !== 0 ? (
                <>
                    <Table columns={columns} data={data?.data ? data?.data : []} rootData={data} />
                    {/* <SimpleGrid columns={3} gap="0px 20px">
                        <FormSection>
                            <DataView
                                title="Total Sales"
                                value={`${formatCurrency(data?.data?.total_sales)}`}
                            />
                        </FormSection>
                        <FormSection>
                            <DataView title="Total Bookings" value={data?.data?.total_bookings} />
                        </FormSection>
                        <FormSection>
                            <DataView
                                title="Total Profit/Loss"
                                value={`${formatCurrency(data?.data?.total_profit_loss)}`}
                            />
                        </FormSection>
                    </SimpleGrid> */}
                </>
            ) : (
                <Text>No data available.</Text>
            )}
        </>
    );
};

export default TableView;

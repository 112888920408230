import React, { useEffect, useState } from 'react';
import {
    Flex,
    Grid,
    Button,
    Alert,
    AlertIcon,
    Text,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import { Field, useGet, useHttp } from '@builtbypixel/nucleus';
import FormSection from '../../../components/FormSection';
import useSWR from 'swr';
import { useWatch, useFormContext, useForm } from 'react-hook-form';
import EmailField from '../../../components/Fields/EmailField';
import AddressFields from '../../../components/AddressFields';
import { findAddress } from '../../../utils/postcodes.io';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { AiFillCloseCircle } from 'react-icons/ai';
const Details = () => {
    const Http = useHttp();
    const email = useWatch({ name: 'email' });
    const user_type = useWatch({ name: 'user_type' });
    const watchAddress = useWatch({ name: 'address' });

    const [loading, setLoading] = useState();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [customerLoginLoader, setCustomerLoginLoader] = useState();
    const [loginAsCustomerError, setLoginAsCustomerError] = useState(false);
    const [dbAddress, setDbAddress] = useState(null);

    const { data: userTypes } = useSWR('/user-types', useGet);
    const { data: countries } = useSWR('/countries', useGet);
    const { data: brokerTypes } = useSWR('/broker-types', useGet);

    const [dirtyFields, setDirtyFields] = useState('no match');
    const [showWithSpaces, setShowWithSpaces] = useState('');
    function addSpaceEveryTwoCharacters(str) {
        return str.replace(/(.{2})/g, '$1 ');
    }
    function removeSpaces(str) {
        return str.replace(/\s/g, '');
    }

    function removeCharactersAfter(str) {
        return str.substring(0, 13);
    }
    const handleChangeAndValidate = (name, value) => {
        //first we check for valid NI

        const niRegex = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{1}$/i;

        let niWithNoSpaces = removeCharactersAfter(value);

        niWithNoSpaces = removeSpaces(niWithNoSpaces);
        setShowWithSpaces(addSpaceEveryTwoCharacters(niWithNoSpaces));

        if (niWithNoSpaces.match(niRegex)) {
            setDirtyFields('YAY😱');
            //it matches so add some spaces in
            setValue(name, addSpaceEveryTwoCharacters(value));
        } else {
            setDirtyFields('no match');
            setValue(name, '');
        }
        // setShowWithSpaces(addSpaceEveryTwoCharacters(niWithNoSpaces));
    };
    const { setValue, getValues, register, reset } = useFormContext();
    useEffect(() => {
        if (watchAddress && !dbAddress) {
            setDbAddress(watchAddress);
        }
    }, [watchAddress, dbAddress]);

    const sendResetPasswordEmail = () => {
        setLoading(true);
        Http.post('password-reset', { email })
            .then(() => {
                setLoading(false);
                setSuccess(true);
                setError(false);
            })
            .catch(() => {
                setSuccess(false);
                setError(true);
                setLoading(false);
            });
    };
    const baseUrl = process.env.REACT_APP_LOGIN_AS;

    const loginAsCustomer = () => {
        setCustomerLoginLoader(true);
        Http.post('login-as-user', { email })
            .then((res) => {
                setCustomerLoginLoader(false);
                setTimeout(() => {
                    window.open(baseUrl + '/dashboard?loginAs=' + res.data.success.token);
                }, 500);
            })
            .catch(() => {
                setCustomerLoginLoader(false);
                setLoginAsCustomerError(true);
            });
    };

    const getUserText = () => {
        return user_type?.name === 'Business'
            ? user_type?.name?.toLowerCase() + ' user '
            : user_type?.name !== 'Personal'
            ? user_type?.name?.toLowerCase()
            : 'user';
    };
    const loadOptions = (inputValue, callback) => {
        if (!inputValue || inputValue.length < 5) return;

        //  Http.get(`https://marblebe.stagelab.co.uk/resolve-postcode/${inputValue}`).then((res)=>console.log(res)).catch((err)=>console.log(err))
        findAddress(inputValue).then((res) =>
            callback(
                res?.data?.addresses?.map((hit) => ({
                    value: hit,
                    label: hit.line_1,
                    postcode: inputValue,
                })),
            ),
        );
    };

    console.log(watchAddress, '<--------- watchAddress ADDRESS IS');

    const onChange = async (option) => {
        // const res = await resolveAddress(option.value);
        //const address = res?.data?.result;
        // setSelectedAddress(address);

        const formattedAddress = {
            address_line_one: option?.value.line_1,
            address_line_two: option?.value.line_2,
            address_line_three: option?.value.line_3,
            address_city: option?.value.town_or_city,
            address_postcode: option?.postcode,
            address_country: {
                id: 229,
                name: 'United Kingdom',
                code: 'GB',
                currency_name: 'British pound',
                dial_code: '+44',
                currency_symbol: '£',
                currency: 'GBP',
                created_at: null,
                updated_at: null,
            },
        };
        //  console.log(formattedAddress,"<--------- formattedAddress ADDRESS IS",);

        setDbAddress(formattedAddress);
        //    setValue('address', formattedAddress);
        // setValue("line_2", address.line_2);
        // setValue("line_3", address.line_3);
        // setValue("post_town", address.post_town);
        // setValue("county", address.county);
        // setValue("postcode", address.postcode);
    };

    const handleCountryChange = (country) => {
        setDbAddress((old) => {
            return { ...old, address_country: country };
        });
    };

    useEffect(() => {
        if (dbAddress) {
            setValue('address', dbAddress);
        }
    }, [dbAddress]);

    const { handleSubmit, control } = useForm({});

    return (
        <>
            <FormSection title="Company Details" mt="0px">
                {' '}
                <Field
                    name="company_name"
                    label="Company Name"
                    component="text"
                    rules={{ required: true }}
                />
                <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                    <Field
                        name="first_name"
                        label="Administrator First Name"
                        component="text"
                        rules={{ required: true }}
                    />
                    <Field name="middle_name" label="Administrator Middle Name" component="text" />
                    <Field
                        name="last_name"
                        label="Administrator Last Name"
                        component="text"
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid templateColumns="2fr 1fr" gap="30px">
                    {/* <Field
                        name="email"
                        label="Email Address"
                        component="text"
                        type="email"
                        rules={{ required: true }}
                    /> */}
                    <Flex align="center">
                        <EmailField name="email" email={email} />
                    </Flex>
                    <Field
                        name="user_type"
                        component="select"
                        label="User Type"
                        options={userTypes?.data ?? []}
                        labelKey="name"
                        valueKey="id"
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                    {user_type?.name === 'Broker' ? (
                        <>
                            <Field
                                name="broker_type"
                                label="Broker Type"
                                component="select"
                                labelKey="name"
                                valueKey="id"
                                options={brokerTypes?.data}
                                help="type of broker"
                            />
                            <Field
                                name="domain"
                                label="Broker Domain"
                                placeholder="Enter Broker Domain"
                                component="text"
                            />
                            <Field
                                name="commission"
                                label="Broker Commission"
                                placeholder="Enter Broker Comission (%)"
                                component="text"
                                help="The percentage comission given to the broker"
                            />
                        </>
                    ) : null}
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr" gap="30px" alignItems="start">
                    {/* <Field
                        name="national_insurance"
                        label="National Insurance"
                        component="text"
                        rules={{ required: true }}
                    /> */}
                    <Flex direction={'column'} minWidth="130px" w="100%" m="0">
                        <Text fontSize="11px" fontWeight="semibold" color="gray.600" mb="3px">
                            National Insurance
                        </Text>
                        {console.log(useFormContext())}
                        <InputGroup size="md">
                            <Input
                                placeholder="National Insurance Number"
                                bg="white"
                                // isDisabled={true}
                                label="National Insurance"
                                textTransform={'uppercase'}
                                _placeholder={{
                                    textTransform: 'capitalize',
                                }}
                                value={
                                    getValues(`national_insurance`)
                                        ? getValues(`national_insurance`)
                                        : showWithSpaces
                                }
                                defaultValue={getValues('national_insurance')}
                                onChange={(e) =>
                                    handleChangeAndValidate(`national_insurance`, e.target.value)
                                }
                            />
                            <InputRightElement
                                width="1.5rem"
                                cursor={'pointer'}
                                onClick={() => {
                                    reset();
                                    handleChangeAndValidate('national_insurance', '');
                                }}
                            >
                                <AiFillCloseCircle />
                            </InputRightElement>
                        </InputGroup>
                    </Flex>
                    <Field
                        name="dob"
                        label="Date of Birth"
                        component="text"
                        type="date"
                    />
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                    <Field
                        name="phone_number"
                        label="Phone Number"
                        component="text"
                        type="text"
                        rules={{ required: true }}
                    />
                    <Field
                        name="mobile_number"
                        label="Mobile Number"
                        component="text"
                        type="text"
                    />
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                    <Field
                        name="payment_terms"
                        label="Payment Terms (days)"
                        component="text"
                        help="number of days payment must be made by"
                    />
                    <Field
                        name="discount_amount"
                        label="Discount Amount"
                        component="text"
                        type="number"
                        help={`discount eligible to this ${getUserText()}`}
                    />
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr">
                    <Field
                        name="credit_terms"
                        label="Credit Amount"
                        component="text"
                        type="number"
                        help={`credit balance available to this ${getUserText()}`}
                    />
                </Grid>
                {success && (
                    <Alert status="success" my="15px">
                        <AlertIcon /> Password email successfully generated.
                    </Alert>
                )}
                {error && (
                    <Alert status="error" my="15px">
                        <AlertIcon /> There was a problem sending the reset email.
                    </Alert>
                )}
                <Grid templateColumns="1fr 1fr 1fr">
                    <Button
                        isLoading={loading}
                        colorScheme="blue"
                        isDisabled={loading}
                        onClick={() => sendResetPasswordEmail()}
                    >
                        Send Password Reset Email
                    </Button>
                </Grid>
                <Grid templateColumns="1fr 1fr 1fr">
                    <Button
                        colorScheme="blue"
                        loading={customerLoginLoader}
                        isDisabled={customerLoginLoader}
                        variant="outline"
                        onClick={() => loginAsCustomer()}
                        mt={5}
                    >
                        Login As Customer
                    </Button>
                </Grid>
                {loginAsCustomerError && (
                    <Alert status="error" my="15px">
                        <AlertIcon /> There was a problem logging into this users account.
                    </Alert>
                )}
            </FormSection>
            <AddressFields />
        </>
    );
};

export default Details;

import { Field, useGet } from '@builtbypixel/nucleus';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    useDisclosure,
    Button,
    ModalFooter,
} from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';

const AddressEdit = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data: countries } = useSWR('/countries', useGet);
    return (
        <>
            <Button
                isFullWidth={false}
                justifyContent="flex-start"
                size="sm"
                variant="link"
                colorScheme="blue"
                onClick={() => onOpen()}
            >
                Edit Address
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />

                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>Edit Billing Address</ModalHeader>
                    <ModalBody>
                        <Field
                            component="text"
                            name="customer.address.address_line_one"
                            label="Address Line One"
                            rules={{ required: true }}
                        />
                        <Field
                            component="text"
                            name="customer.address.address_line_two"
                            label="Address Line Two"
                            rules={{ required: true }}
                        />

                        <Field
                            component="text"
                            name="customer.address.address_line_three"
                            label="Address Line Three"
                        />
                        <Field
                            component="text"
                            name="customer.address.address_city"
                            label="City"
                            rules={{ required: true }}
                        />

                        <Field
                            component="text"
                            name="customer.address.address_postcode"
                            label="PostCode"
                        />
                        <Field
                            component="select"
                            options={countries?.data}
                            labelKey="name"
                            valueKey="id"
                            name="customer.address.address_country"
                            label="Country"
                            rules={{ required: true }}
                            defaultValue={{
                                code: 'GB',
                                created_at: null,
                                currency: 'GBP',
                                currency_name: 'British pound',
                                currency_symbol: '£',
                                dial_code: '+44',
                                id: 229,
                                name: 'United Kingdom',
                                updated_at: null,
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => onClose()} colorScheme="green">
                            Save Address
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddressEdit;

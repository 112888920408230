import React, { useState } from 'react';
import { LinkButton, ListWrapper, useHttp, PopConfirm, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import setup from './setup';
import { BiCopy } from 'react-icons/bi';
import {
    Text,
    useToast,
    Box,
    Flex,
    Stack,
    Badge,
    IconButton,
    Tooltip,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Button,
    PopoverArrow,
} from '@chakra-ui/react';
import MiniEditView from '../../Nucleus/components/EditView/Mini.jsx';
import debounce from 'lodash.debounce';
import BulkUploadModal from '../../components/Modals/BulkUploadModal';
import ListView from '../../Nucleus/components/ListView';

const List = (props) => {
    const [duplicating, setDuplicating] = useState(false);

    const Http = useHttp();
    const toast = useToast();

    const { data: providers } = useSWR('/providers?simple=true&pagination=999&page=1', useGet);
    const { data: venues } = useSWR('/venues?simple=true', useGet);
    const { data: locations } = useSWR('/locations?simple=true', useGet);
    const { data: delivery_methods } = useSWR('/learning-styles', useGet);
    const { data: eventStatuses } = useSWR('/event-statuses', useGet);

    const cloneEvent = (id) => {
        setDuplicating(true);

        Http.post(`/events/clone/${id}`)
            .then((res) => {
                window.location.href = `/events/edit/${res.data.data.id}`;
            })
            .catch(() => {
                toast({
                    status: 'error',
                    message: 'Error cloning course',
                });
            });

        setDuplicating(false);
    };

    const asyncSearch = debounce((val, endpoint, callback) => {
        Http.get(`/${endpoint}?search=${val}`).then((res) => {
            callback(res.data.data);
        });
    }, 500);

    const extraMenu = () => {
        return (
            <MiniEditView>
                <BulkUploadModal event={true} />
            </MiniEditView>
        );
    };

    const columns = [
        {
            Header: 'Actions',
            accessor: 'clone',
            width: '50px',
            Cell: ({ row }) => (
                <Stack isInline spacing="15px">
                    <PopConfirm
                        onConfirm={() => cloneEvent(row.original.id)}
                        title={`Are you sure you want to clone "${row.original.course.title}".`}
                    >
                        <Tooltip label="Clone">
                            <IconButton
                                size="sm"
                                variant="link"
                                icon={<BiCopy fontSize="18px" />}
                                isLoading={duplicating}
                                isDisabled={duplicating}
                            />
                        </Tooltip>
                    </PopConfirm>
                </Stack>
            ),
            disableSortBy: true,
        },
        {
            Header: 'Course',
            accessor: 'course.code',
            disableSortBy: true,
            Cell: ({ row }) => (
                <Popover trigger="hover">
                    <PopoverTrigger>
                        <a
                            target="_blank"
                            href={`${setup.model}/edit/${row.original[setup.accessor]}`}
                        >
                            <Flex
                                justify="center"
                                direction="column"
                                cursor="pointer"
                                _hover={{ color: 'secondary' }}
                            >
                                <Text fontWeight="bold">{row.original.event_code}</Text>
                                <Text isTruncated maxWidth="150px">
                                    {row.original.course.title}
                                </Text>
                                <Text as="pre">{row.original.provider.name}</Text>
                            </Flex>
                        </a>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <Text fontWeight="bold">{row.original.event_code}</Text>
                            <Text>{row.original.course.title}</Text>
                            <Text as="pre">{row.original.provider.name}</Text>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            ),
        },

        {
            Header: 'Dates',
            accessor: 'start_date',
            Cell: ({ row }) => {
                return (
                    <Stack align="center" spacing="5px" isInline>
                        <Text>
                            {row.original?.learningItem?.elearning
                                ? 'N/A'
                                : row.original.start_date !== null
                                    ? row.original.start_date.replaceAll('-', '/').slice(0, -8)
                                    : null}
                        </Text>
                        <Text>-</Text>
                        <Text>
                            {row.original?.learningItem?.elearning
                                ? 'N/A'
                                : row.original.end_date !== null
                                    ? row.original.end_date.replaceAll('-', '/').slice(0, -8)
                                    : null}
                        </Text>
                    </Stack>
                );
            },
        },

        {
            Header: 'Price',
            accessor: 'price',
            Cell: ({ row }) => {
                return row.original.discount ? (
                    <Flex>
                        <Text textDecoration="line-through">
                            £{Number(row.original.price).toFixed(2)}
                        </Text>
                        <Text ml="1em">
                            £{Number(row.original.price - row.original.discount).toFixed(2)}
                        </Text>
                    </Flex>
                ) : (
                    <Text>£{Number(row.original.price).toFixed(2)}</Text>
                );
            },
        },
        {
            Header: 'Bundle Price',
            accessor: 'bundle_price',
            Cell: ({ row }) => {
                return row.original?.bundle_price ? (
                    <Text>£{Number(row.original?.bundle_price ?? 0).toFixed(2)}</Text>
                ) : (
                    <Text>N/A</Text>
                );
            },
        },
        {
            Header: 'Location',
            accessor: 'locations.location_name',
            Cell: ({ row }) => {
                return (
                    <Flex justify="center" direction="column">
                        <Text>
                            {!row.original.locations ? (
                                <Badge colorScheme="blue">Online</Badge>
                            ) : (
                                row.original.locations.location_name
                            )}
                        </Text>
                        <Text>
                            {row.original.venues[0].hasOwnProperty('title')
                                ? row.original.venues[0]?.title
                                : null}
                        </Text>
                    </Flex>
                );
            },
            disableSortBy: true,
        },

        {
            Header: 'Delivery Method',
            accessor: 'learningItem.name',
        },
        {
            Header: 'Status',
            accessor: 'event_status.name',
        },
        {
            Header: 'Bundle Version',
            accessor: 'is_bundle',
            Cell: ({ row }) => (
                <>
                    {row.original?.is_bundle ? (
                        <Badge colorScheme="green">Yes</Badge>
                    ) : (
                        <Badge colorScheme="red">No</Badge>
                    )}
                </>
            ),
        },
        {
            Header: 'Published',
            accessor: 'published',
            Cell: ({ row }) => (
                <>
                    {row.original.published ? (
                        <Badge colorScheme="green">Yes</Badge>
                    ) : (
                        <Badge colorScheme="red">No</Badge>
                    )}
                </>
            ),
        },
        {
            Header: 'Places',
            accessor: '',
            Cell: ({ row }) =>
                row.original?.learningItem?.name === 'E-Learning' ? (
                    <Text fontWeight="bold">{row.original.delegate_count ?? 0}</Text>
                ) : (
                    <Stack isInline spacing="5px">
                        <Text fontWeight="bold">
                            {row.original.delegate_count ?? 0}/{row.original.availability}
                        </Text>
                        <Text>
                            {(
                                (parseInt(row.original.delegate_count ?? 0) /
                                    parseInt(row.original.availability ?? 0)) *
                                100
                            ).toFixed(0)}
                            %
                        </Text>
                    </Stack>
                ),
            disableSortBy: true,
        },
        {
            Header: 'Profit/Loss',
            accessor: '',
            Cell: ({ row }) => (
                <Stack>
                    <Tooltip
                        hasArrow
                        label={
                            <>
                                <Text fontSize={12}>
                                    Revenue: £{row.original.revenue.toFixed(2)}
                                </Text>
                                <Text fontSize={12}>
                                    Total Cost: £{row.original.total_event_cost.toFixed(2)}
                                </Text>
                            </>
                        }
                        bg="gray.300"
                        color="black"
                    >
                        <Badge
                            fontSize="12px"
                            padding="5px"
                            colorScheme={
                                row.original.profit_loss.toString()?.includes('-') ? 'red' : 'green'
                            }
                        >
                            £{row.original.profit_loss.toFixed(2)}
                        </Badge>
                    </Tooltip>
                </Stack>
            ),
            disableSortBy: true,
        },
    ];

    const filters = [
        {
            component: 'select',
            options: eventStatuses?.data,
            labelKey: 'name',
            valueKey: 'id',
            label: 'Status',
            name: 'status',
            placeholder: 'Select Status',
            defaultValue: { id: 4, name: 'Scheduled' },
        },
        {
            component: 'select',
            options: [{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }],
            label: 'Is Marble',
            name: 'is_marble',
            placeholder: 'Select an Option',
        },
        {
            component: 'select',
            options: providers?.data,
            labelKey: 'name',
            label: 'Provider',
            valueKey: 'id',
            name: 'provider',
            placeholder: 'Select Provider',
        },

        {
            component: 'select',
            label: 'Location',
            options: locations?.data,
            labelKey: 'location_name',
            valueKey: 'id',
            name: 'locations',
            placeholder: 'Select location',
        },

        {
            component: 'select',
            label: 'Delivery Method',
            options: delivery_methods?.data,
            labelKey: 'name',
            valueKey: 'id',
            name: 'delivery_method',
            placeholder: 'Select delivery method',
        },

        {
            component: 'select',
            label: 'Venue',
            options: venues?.data,
            //loadOptions: (e) => asyncSearch(e, 'venue'),
            labelKey: 'title',
            valueKey: 'id',
            name: 'venue',
            placeholder: 'Search Venue',
            //isAsync: true,
        },
        {
            component: 'select',
            label: 'Course',
            loadOptions: (e, cb) => asyncSearch(e, 'courses', cb),
            labelKey: 'title',
            valueKey: 'id',
            name: 'course',
            placeholder: 'Search Course',
            isAsync: true,
        },
        {
            component: 'date',
            label: 'Start Date',
            name: 'start',
            placeholder: 'Select Start',
        },
        {
            component: 'date',
            label: 'End Date',
            name: 'end',
            placeholder: 'Select End',
        },
    ];

    return (
        <Box width="100%">
            {/* <ListWrapper
                filters={filters}
                setup={setup}
                columns={columns}
                {...props}
                extraMenu={extraMenu}
            /> */}
            <ListView
                filters={filters}
                setup={setup}
                columns={columns}
                {...props}
                extraMenu={extraMenu}
                source="events"
            />
            {/* <ListWrapper
                filters={filters}
                setup={setup}
                columns={columns}
                extraMenu={extraMenu}
                {...props}
            /> */}
        </Box>
    );
};

export default List;

import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    ModalFooter,
    Stack,
    Alert,
    Flex,
    AlertIcon,
    Divider,
    Switch,
    Box,
    Text,
    Input,
    Checkbox,
} from '@chakra-ui/react';
import PopConfirm from '../../../../Nucleus/components/PopConfirm';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Field } from '@builtbypixel/nucleus';
import { BsCreditCard } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';
import { FaFileInvoice } from 'react-icons/fa';
import CardDetails from './CardDetails';
import { useParams } from 'react-router';
import Sage3DSecure from './Sage3DSecure';
import CustomerDetails from './CustomerDetails';
import { bookingAmountState } from '../../atom';
import { MdPayment } from 'react-icons/md';

const BookingConfirm = ({ isSubmitting, sage, setBookingType }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { type } = useParams();
    const { setValue, submit, watch } = useFormContext();
    const isProvisional = useWatch({ name: 'provisional_booking' });
    const isCreditBooking = useWatch({ name: 'credit_booking' });
    const isFuture = useWatch({ name: 'future_payment' });
    const events = useWatch({ name: 'events' });
    const user = watch('customer');
    const status = useWatch({ name: 'status' });
    const invoice = useWatch({ name: 'invoice' });
    const credit_terms_value = useWatch({ name: 'credit_terms' });
    const event_total = useRecoilValue(bookingAmountState);
    const [submitting, setSubmitting] = useState(false);
    const [updatedCreditTerms, setUpdatedCreditTerms] = useState(0);
    const [emailDelegates, setEmailDelegates] = useState();

    const [useCredit, setUseCredit] = useState(false); //checkbox
    const [hideCardDetails, setHideCardDetails] = useState(false); //card details -- show or hide based on credit terms value
    useEffect(() => {
        setEmailDelegates(false);
    }, []);
    const setInvoice = () => {
        setValue('future_payment', true);
        setValue('card_details', null);
        setValue('credit_booking', useCredit);
        setValue('payment_terms', user.payment_terms ? user.payment_terms : 0);
    };

    const setCreditCard = () => {
        setValue('future_payment', false);
        setValue('credit_booking', false);
    };

    const setCreditBooking = () => {
        if (useCredit) {
            setValue('credit_booking', true);
            setValue('future_payment', false);
            setValue('payment_terms', false);
            setValue('send_del_emails', emailDelegates);

            // onClose();
            if (hideCardDetails) setValue('card_details', null);
            setSubmitting(true);
        }
    };

    useEffect(() => {
        if (status) {
            setBookingType(status);
        }
    }, [status]);

    useEffect(() => {
        if (useCredit) {
            let hideCardDetails =
                event_total - credit_terms_value <= 0 || event_total - updatedCreditTerms <= 0;
            setHideCardDetails(hideCardDetails);
        }
    }, [credit_terms_value, useCredit]);

    useEffect(() => {
        setValue('credit_booking', useCredit);
        setValue('credit_terms', user?.credit_terms);
    }, [useCredit]);

    const { errors } = useFormState();
    const toggle = () => {
        setEmailDelegates((prevState) => !prevState);
    };
    const markAsBACS = () => {
        setValue('status', { id: 7 });
        setValue('send_del_emails', emailDelegates);
        submit();
    };

    const handleSetPrice = (val) => {
        setValue('credit_terms', val);
        setUpdatedCreditTerms(parseInt(val));
    };

    return (
        <>
            {type === 'create' ? (
                <Button
                    onClick={() => {
                        if (isProvisional) {
                            submit();
                        } else {
                            onOpen();
                        }
                    }}
                    colorScheme="green"
                    isLoading={isSubmitting}
                    isDisabled={
                        !events?.every(
                            (evt) => evt.quantity <= evt.availability - (evt.delegate_count ?? 0),
                        )
                    }
                // isDisabled={!isValid}
                >
                    {isProvisional
                        ? 'Create Provisional Booking'
                        : isCreditBooking
                            ? 'Use Credit Terms'
                            : 'Continue Booking'}
                </Button>
            ) : (
                <Button
                    onClick={() => {
                        if (status && status.name === 'Completed') {
                            submit();
                        } else {
                            onOpen();
                        }
                    }}
                    isLoading={isSubmitting}
                    colorScheme="blue"
                    leftIcon={<MdPayment fontSize="20px" />}
                // isDisabled={!isValid}
                >
                    {status?.name === 'Completed' ? 'Edit Booking' : 'Confirm payment for booking'}
                </Button>
            )}

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay />

                <ModalContent maxWidth="900px">
                    <ModalCloseButton />
                    <ModalHeader>Confirm payment method</ModalHeader>
                    <ModalBody>
                        <Flex w="100%" direction="column">
                            <Stack isInline spacing="25px" w="100%" mb="15px">
                                {user?.payment_terms && user?.payment_terms !== 0 && (
                                    <Button
                                        size="lg"
                                        isFullWidth
                                        variant="outline"
                                        border={isFuture ? '2px' : '1px'}
                                        leftIcon={<FaFileInvoice fontSize="19px" />}
                                        colorScheme={isFuture ? 'blue' : 'gray'}
                                        onClick={() => setInvoice()}
                                    >
                                        Raise Invoice
                                    </Button>
                                )}
                                <Button
                                    size="lg"
                                    isFullWidth
                                    variant="outline"
                                    border={!isFuture ? '2px' : '1px'}
                                    leftIcon={<BsCreditCard fontSize="22px" />}
                                    colorScheme={!isFuture ? 'blue' : 'gray'}
                                    onClick={() => setCreditCard()}
                                >
                                    Pay by Credit / Debit Card
                                </Button>
                            </Stack>

                            {user?.credit_terms && (
                                // <Flex width="100%" justify="flex-end">
                                <Flex my="0.5em" direction="column" w="100%">
                                    <Flex align="center" justify="flex-end">
                                        <Text mr="0.8em" color="#3182CE" fontWeight={'bold'}>
                                            {`£${user.credit_terms} credit available, would you like to use credit for the booking?`}
                                        </Text>
                                        <Checkbox
                                            defaultChecked={useCredit}
                                            onChange={(e) => setUseCredit(e.target.checked)}
                                        />
                                    </Flex>
                                    {useCredit && (
                                        <Flex ml="auto" align="center" mt="1em">
                                            <Field
                                                name="credit_terms"
                                                defaultValue={user?.credit_terms}
                                                onChange={handleSetPrice}
                                                component="number"
                                                label="Credit terms"
                                                step={0.01}
                                            />
                                        </Flex>
                                    )}
                                    <Divider />
                                </Flex>
                                // </Flex>
                            )}

                            {user && user.payment_terms !== 0 && isFuture && (
                                <Box>
                                    {invoice !== null && invoice !== undefined ? (
                                        <Alert mb="20px" status="success">
                                            <AlertIcon />
                                            An invoice has been raised for this booking
                                        </Alert>
                                    ) : (
                                        <Alert mb="20px">
                                            This user has payment terms. Confirm the terms below
                                            before submitting
                                        </Alert>
                                    )}
                                    <Flex direction="column" gap="20px">
                                        {' '}
                                        <Text mb="5px">Email Delegates?</Text>
                                        <Switch
                                            size="lg"
                                            defaultChecked={emailDelegates}
                                            isChecked={emailDelegates}
                                            onChange={toggle}
                                            mb="10px"
                                        />
                                    </Flex>

                                    {(invoice === null || invoice === undefined) && (
                                        <Field
                                            name="payment_terms"
                                            defaultValue={user?.payment_terms}
                                            component="number"
                                            maxWidth="160px"
                                            label="Payment terms (days)"
                                            isDisabled={invoice !== null && invoice !== undefined}
                                        />
                                    )}
                                    {invoice !== null && invoice !== undefined && (
                                        <PopConfirm
                                            onConfirm={() => markAsBACS()}
                                            title="Are you sure you want to mark this invoice as paid?"
                                        >
                                            <Button colorScheme="green">
                                                Mark as paid by BACS
                                            </Button>
                                        </PopConfirm>
                                    )}
                                </Box>
                            )}

                            {!isFuture && !sage && (
                                <Flex w="100%">
                                    <CustomerDetails />
                                    {hideCardDetails ? (
                                        <Flex direction="column" w="100%">
                                            <Text my="1em">Card Payment Not Required</Text>
                                        </Flex>
                                    ) : (
                                        <CardDetails />
                                    )}
                                </Flex>
                            )}

                            {!isFuture && sage && (
                                <Flex>
                                    <Sage3DSecure sageInfo={sage} setSubmitting={setSubmitting} />
                                </Flex>
                            )}
                            {Object.keys(errors).length !== 0 && (
                                <Alert status="error" mt="15px">
                                    <AlertIcon />
                                    <Flex direction="column">
                                        <Text>
                                            You are missing some required fields. Please check.
                                        </Text>
                                        <ErrorMessage
                                            errors={errors}
                                            name="customer.address.address_line_one"
                                            render={({ messages }) =>
                                                messages &&
                                                Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                ))
                                            }
                                        />
                                    </Flex>
                                </Alert>
                            )}
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        {!isFuture && (
                            <Flex direction="row" justifyContent="space-between" w="100%">
                                <Flex direction="row" gap="10px">
                                    <Text fontSize="1rem" mr="10px">
                                        Email Delegates?
                                    </Text>
                                    <Switch
                                        size="lg"
                                        defaultChecked={emailDelegates}
                                        isChecked={emailDelegates}
                                        onChange={toggle}
                                    />
                                </Flex>
                                <Button
                                    colorScheme="green"
                                    onClick={() => {
                                        if (Number(user?.credit_terms) > event_total && useCredit)
                                            setCreditBooking();
                                        submit();
                                    }}
                                    isLoading={isSubmitting}
                                    isDisabled={
                                        useCredit
                                            ? isNaN(updatedCreditTerms)
                                                ? true
                                                : false
                                            : isSubmitting
                                    }
                                >
                                    Confirm Payment
                                </Button>
                            </Flex>
                        )}

                        {user &&
                            user.payment_terms !== 0 &&
                            isFuture &&
                            (invoice === null || invoice === undefined) && (
                                <Button
                                    isLoading={isSubmitting}
                                    isDisabled={isSubmitting}
                                    colorScheme="green"
                                    onClick={() => {
                                        setInvoice();
                                        submit();
                                    }}
                                >
                                    Raise Invoice
                                </Button>
                            )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default BookingConfirm;

// : (
//     <ModalContent>
//         <ModalCloseButton />
//         <ModalHeader>Confirm Payment Terms</ModalHeader>
//         <ModalBody>
//             {event_total > updatedCreditTerms || isNaN(updatedCreditTerms) ? (
//                 <Alert status="warning" mb="15px">
//                     <AlertIcon />
//                     The amount of credit you have provided are not enough to
//                     purchase this event
//                 </Alert>
//             ) : (
//                 <Alert status="success" mb="15px">
//                     <AlertIcon />
//                     You have enough credits to purchase this course
//                 </Alert>
//             )}
//             <Flex w="100%" direction="column">
//                 <Stack spacing="25px" w="100%" mb="15px">
//                     <form method="post" target="result">
//                         <Field
//                             name="credit_terms"
//                             defaultValue={parseInt(user?.credit_terms).toFixed(2)}
//                             onChange={handleSetPrice}
//                             component="number"
//                             label="Credit terms"
//                         />
//                         <Button
//                             size="lg"
//                             isFullWidth
//                             variant="outline"
//                             border={!isFuture ? '2px' : '1px'}
//                             colorScheme={!isFuture ? 'blue' : 'gray'}
//                             onClick={() => {
//                                 setCreditBooking();
//                                 submit();
//                             }}
//                             isDisabled={
//                                 event_total > updatedCreditTerms ||
//                                 isNaN(updatedCreditTerms)
//                                     ? true
//                                     : false
//                             }
//                             type="submit"
//                             mt={0}
//                         >
//                             Pay with Credit Terms
//                         </Button>
//                     </form>
//                 </Stack>
//             </Flex>
//         </ModalBody>
//     </ModalContent>
// )

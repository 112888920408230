import React, { useEffect, useState } from 'react';
import {
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    ModalHeader,
    ModalCloseButton,
    Text,
    Grid,
    Flex,
    Stack,
    useDisclosure,
    Modal,
    Box,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';

import { Field, useGet } from '@builtbypixel/nucleus';
import moment from 'moment';
import { useWatch, useFormContext, defaultValue } from 'react-hook-form';
import SplitDates from './SplitDates';
import { BsArrowRight } from 'react-icons/bs';
import useSWR from 'swr';
import { useParams } from 'react-router';
import TrainerAvailability from '../TrainerAvailability';
import AllTrainerAvailability from '../../../../containers/TrainersCalendar/Fields/TrainersComponent';

import TrainerDetails from '../TrainerDetails';

const StartEndDates = () => {
    const { type, model } = useParams();
    const { setValue, data, watch, getValues } = useFormContext();
    const { data: priceTypes } = useSWR('/price-types', useGet);
    const [resetDates, setResetDates] = useState(null);
    const [datesChanged, setDatesChanged] = useState(false);

    const start = watch('start_date_field');
    const end = watch('end_date_field');
    const course = watch('course');

    const trainerType = watch('trainer_price_type');

    const event_type = watch('event_type');

    const startTime = watch('start_time');

    const endTime = watch('end_time');

    const currentDates = watch('dates');

    const currentTrainer = watch('trainers');

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenCalendar,
        onOpen: onOpenCalendar,
        onClose: onCloseCalendar,
    } = useDisclosure();

    // begin reset event dates process
    // by first setting the dates value to an empty array
    // then giving both start_time & end_time values so no error appears once the modal pops up
    const reset_events = () => {
        setResetDates(true);
        setValue('dates', []);
        setValue('start_time', '09:00');
        setValue('end_time', '17:00');
    };

    useEffect(() => {
        const getDaysBetweenDates = () => {
            let dates = [];

            var currDate = moment(start).startOf('day');
            var lastDate = moment(end).startOf('day');

            dates.push(currDate.toDate());

            while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                dates.push(currDate.clone().toDate());
            }

            return dates;
        };

        var dates = [];

        if ((startTime && endTime) || (isOpen && startTime && endTime)) {
            if (datesChanged) {
                setDatesChanged(false);
                return;
            } else {
                const datesMap = getDaysBetweenDates();

                datesMap.forEach((date, i) => {
                    let obj = {};
                    const updatedStart = moment(date)
                        .set('hour', startTime.substring(0, 2))
                        .set('minute', startTime.substring(3, 5))
                        .set('second', '00');

                    const updatedEnd = moment(date)
                        .set('hour', endTime.substring(0, 2))
                        .set('minute', endTime.substring(3, 5))
                        .set('second', '00');

                    obj.start = updatedStart.format('YYYY-MM-DD HH:mm:ss');
                    obj.end = updatedEnd.format('YYYY-MM-DD HH:mm:ss');
                    obj.start_time = startTime;
                    obj.end_time = endTime;
                    obj.type = trainerType;
                    obj.day = date;
                    obj.switchChecked = true;
                    obj.index = i;
                    dates.push(obj);

                    setValue('start_date', obj.start);
                    setValue('end_date', obj.end);
                });

                const updateStartField = moment(start)
                    .set('hour', startTime.substring(0, 2))
                    .set('minute', startTime.substring(3, 5))
                    .set('second', '00')
                    .format('YYYY-MM-DD HH:mm:ss');

                const updatedEndField = moment(end)
                    .set('hour', endTime.substring(0, 2))
                    .set('minute', endTime.substring(3, 5))
                    .set('second', '00')
                    .format('YYYY-MM-DD HH:mm:ss');

                setValue('start_date', updateStartField);
                setValue('end_date', updatedEndField);

                setValue('dates', dates);
            }
        }
    }, [start, end, startTime, endTime, setValue, trainerType, isOpen, resetDates]);

    useEffect(() => {
        setTimeout(() => {
            if (type === 'edit') {
                var dates = [];
                var datesMap = data?.dates;
                datesMap.forEach((date, i) => {
                    let obj = {
                        id: date.id,
                        start: date.start,
                        end: date.end,
                        switchChecked: true,
                        type: date.type,
                    };
                    dates.push(obj);
                });
                setValue('dates', dates);
            }
        }, 1000);
    }, [setValue, startTime, endTime, data?.dates, model, type]);

    const CreateEventForm = () => {
        return (
            <>
                <Grid templateColumns="1fr 1fr 2fr" gap="30px" w="100%">
                    <Field
                        name="start_time"
                        component="mask"
                        mask="99:99"
                        placeholder="Enter time"
                        label="Start Time"
                        defaultValue="09:00"
                    />
                    <Field
                        name="end_time"
                        component="mask"
                        mask="99:99"
                        placeholder="Enter time"
                        label="End Time"
                        defaultValue="17:00"
                    />
                </Grid>
                <Grid templateColumns="1fr 1fr 2fr" gap="30px" w="100%">
                    <Field
                        name="event_type"
                        component="select"
                        labelKey="name"
                        valueKey="id"
                        label="Event Type"
                        options={[
                            { id: 1, name: 'Block' },
                            { id: 2, name: 'Split' },
                        ]}
                    />
                    <Field
                        name="trainer_price_type"
                        component="select"
                        labelKey="name"
                        valueKey="id"
                        label="Trainer Price Type"
                        options={priceTypes?.data}
                    />
                </Grid>

                <Grid templateColumns="1fr 1fr 2fr" gap="30px" w="100%">
                    <Field
                        name="start_date_field"
                        component="date"
                        closeOnScroll={true}
                        minDate={new Date()}
                        dateFormat="MMMM Do yyyy"
                        timeFormat="HH:mm"
                        isClearable={true}
                        emptyLabel={'Select Date'}
                        label="Start Date"
                        showPopperArrow={false}
                        disabledKeyboardNavigation
                        shouldCloseOnSelect
                        showTimeSelect={false}
                        timeIntervals={15}
                        timeCaption="Time"
                    />

                    {/* <BsArrowRight fontSize="24px" /> */}

                    <Field
                        name="end_date_field"
                        component="date"
                        closeOnScroll={true}
                        minDate={moment(start)
                            .add(parseInt(course?.duration?.days), 'days')
                            .toDate()}
                        dateFormat="MMMM Do yyyy"
                        timeFormat="HH:mm"
                        isClearable={true}
                        emptyLabel={'Select Date'}
                        label="End Date"
                        showPopperArrow={false}
                        disabledKeyboardNavigation
                        shouldCloseOnSelect
                        disabled={!start}
                        timeIntervals={15}
                        timeCaption="Time"
                        showTimeSelect={false}
                    />
                </Grid>

                {event_type && event_type.id === 2 && <SplitDates />}
            </>
        );
    };

    const validateFields = () => {
        if (event_type && trainerType && end && start && currentTrainer) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (type === 'edit') {
                var dates = [];
                var datesMap = data?.dates;
                datesMap.forEach((date, i) => {
                    let obj = {
                        id: date.id,
                        start: date.start,
                        end: date.end,
                        switchChecked: true,
                        type: date.type,
                    };
                    dates.push(obj);
                });
                setValue('dates', dates);
            }
        }, 1000);
    }, [setValue, startTime, endTime, data?.dates, model, type]);

    return (
        <Flex direction="column" bg="white" rounded="md" p="15px" mt="25px">
            <Flex justify={'space-between'}>
                <Text fontSize="17px" fontWeight="bold" mb="15px">
                    Event Dates
                </Text>
                <Modal isOpen={isOpenCalendar} onClose={onCloseCalendar}>
                    <ModalOverlay />
                    <ModalContent padding={10} maxWidth="900px">
                        <AllTrainerAvailability />
                    </ModalContent>
                </Modal>
                <Button onClick={onOpenCalendar}>View Event Calendar</Button>
            </Flex>

            {type === 'create' || model !== 'events' ? (
                <>{CreateEventForm()}</>
            ) : (
                <Flex direction="column" w="100%" rounded="md" bg="#deeef7" p="10px" mb="10px">
                    <Stack spacing="10px">
                        {currentDates?.map((date, i) =>
                            date?.switchChecked ? (
                                <Flex
                                    align="center"
                                    bg="white"
                                    rounded="md"
                                    px="10px"
                                    py="5px"
                                    w="100%"
                                    key={`date-${i}`}
                                >
                                    <Text fontSize="0.9em" w="300px">
                                        {moment(date.start).format('dddd Do MMMM yyyy')}
                                    </Text>
                                    <Text fontSize="0.9em">
                                        {moment(date.start).format('HH:mm')}
                                    </Text>
                                    <Text px="30px" textAlign="center">
                                        -
                                    </Text>
                                    <Text fontSize="0.9em">{moment(date.end).format('HH:mm')}</Text>
                                </Flex>
                            ) : null,
                        )}
                    </Stack>
                    <Button mt={5} onClick={onOpen}>
                        Need to change Event Dates?
                    </Button>
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent padding={10} maxWidth="900px">
                            <ModalHeader>Check Trainer Availability</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {!resetDates ? (
                                    <Box>
                                        <Alert status="warning">
                                            <AlertIcon />
                                            Your previous event dates will be removed once you go
                                            ahead with this process
                                        </Alert>
                                        <Button
                                            variant="primary"
                                            rounded="lg"
                                            mt={5}
                                            onClick={() => reset_events()}
                                        >
                                            Continue
                                        </Button>
                                    </Box>
                                ) : (
                                    <>
                                        {CreateEventForm()}
                                        {currentTrainer?.length === 0 ? (
                                            <TrainerAvailability />
                                        ) : (
                                            <TrainerDetails />
                                        )}
                                    </>
                                )}
                            </ModalBody>
                            {resetDates && (
                                <ModalFooter>
                                    <Button
                                        onClick={() => {
                                            setDatesChanged(true);
                                            onClose();
                                        }}
                                        colorScheme="green"
                                    >
                                        Continue (Don't forget save your changes)
                                    </Button>
                                </ModalFooter>
                            )}
                        </ModalContent>
                    </Modal>
                </Flex>
            )}
        </Flex>
    );
};

export default StartEndDates;

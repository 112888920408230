import React, { useState } from 'react';
import {
    Flex,
    Text,
    Button,
    Input,
    Stack,
    Badge,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';

import ListView from '../../../EventsNew/List';
import { useFormContext, useWatch } from 'react-hook-form';

const EventSearch = () => {
    const { setValue } = useFormContext();
    const events = useWatch({ name: 'events' });
    const broker = useWatch({ name: 'broker' });

    const addEvent = (event, delegates, price) => {
        let _events = events ? [...events] : [];
        _events.push({ ...event, quantity: delegates, delegates: [], price: price });
        setValue('events', _events);
    };

    const isSelected = (id, provider, title) => {
        // return console.log(events, 'log events on selecrt');

        return events.some(
            (e) => e.id === id && e.provider.id === provider.id && e.course?.title === title,
        );
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'course.title',
            Cell: ({ value, row }) => (
                <Flex align="center">
                    {console.log(row, 'row')}
                    <span>
                        <Text fontWeight="bold">{row.original.event_code} - </Text>

                        <Text>{value}</Text>
                        <Text>{row.original.provider.name}</Text>
                    </span>
                </Flex>
            ),
        },

        {
            Header: 'Dates',
            accessor: 'start_date',
            Cell: ({ row }) => {
                return (
                    <Stack align="center" spacing="5px" isInline>
                        <Text>
                            {row.original?.learningItem?.elearning
                                ? 'N/A'
                                : row.original.start_date !== null
                                    ? row.original.start_date.replaceAll('-', '/').slice(0, -8)
                                    : null}
                        </Text>
                        <Text>-</Text>
                        <Text>
                            {row.original?.learningItem?.elearning
                                ? 'N/A'
                                : row.original.end_date !== null
                                    ? row.original.end_date.replaceAll('-', '/').slice(0, -8)
                                    : null}
                        </Text>
                    </Stack>
                );
            },
        },
        {
            Header: 'Delivery Method',
            accessor: 'learningItem.name',
        },
        {
            Header: 'Location',
            accessor: 'locations.location_name',
            Cell: ({ row }) => {
                return (
                    <Flex justify="center" direction="column">
                        <Text>
                            {!row.original.locations ? (
                                <Badge colorScheme="blue">Online</Badge>
                            ) : (
                                row.original.locations.location_name
                            )}
                        </Text>
                        <Text>
                            {row.original.venues[0].hasOwnProperty('title')
                                ? row.original.venues[0]?.title
                                : null}
                        </Text>
                    </Flex>
                );
            },
            disableSortBy: true,
        },
        {
            Header: 'Price',
            accessor: 'price',
            Cell: ({ row }) => {
                return row.original.discount ? (
                    <Flex direction="column">
                        {broker?.broker_type?.name === 'standard' ? (
                            <Text>£{Number(row.original.price).toFixed(2)}</Text>
                        ) : (
                            <>
                                <Text>
                                    £{Number(row.original.price - row.original.discount).toFixed(2)}
                                </Text>
                                <Text textDecoration="line-through" mt={2}>
                                    £{Number(row.original.price).toFixed(2)}
                                </Text>
                            </>
                        )}
                    </Flex>
                ) : (
                    <Text>£{Number(row.original.price).toFixed(2)}</Text>
                );
            },
        },
        {
            Header: 'Delegates',
            accessor: 'availability',
            Cell: ({ value, row }) => (
                <>
                    <Text fontWeight="bold">
                        {row.original.delegate_count ?? 0} / {row.original.availability ?? 0}
                    </Text>
                </>
            ),
        },
        {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => (
                <DelegatesNumber row={row} isSelected={isSelected} addEvent={addEvent} />
            ),
        },
    ];

    return (
        <Flex direction="column" w="100%">
            <ListView
                tableOnly
                columns={columns}
                setup={{ canSelect: false, canSearch: true, endpoint: '/events-providers' }}
            />
        </Flex>
    );
};

const DelegatesNumber = ({ row, isSelected, addEvent }) => {
    const [delegates, setDelegates] = useState(1);
    const [price, setPrice] = useState(row.original.price);
    const maxDelegates = row.original.availability ?? 0 - row.original.delegate_count ?? 0;

    return (
        <Flex align="center" ml="auto" justify="flex-end">
            {!isSelected(row.original.id, row.original.provider, row.original.course?.title) && (
                <>
                    <Flex direction="column">
                        <Text mr="10px" fontSize="11px">
                            Delegates:
                        </Text>
                        <NumberInput
                            size="sm"
                            onChange={(e) => setDelegates(e)}
                            min={1}
                            max={maxDelegates}
                            maxWidth="100px"
                            bg="white"
                            defaultValue={1}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Flex>
                    <Flex direction="column" ml="10px">
                        <Text mr="10px" fontSize="11px">
                            Price:
                        </Text>
                        <NumberInput
                            onChange={(e) => setPrice(e)}
                            min={1}
                            size="sm"
                            maxWidth="100px"
                            bg="white"
                            defaultValue={row.original.price}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Flex>
                </>
            )}
            <Button
                ml="5px"
                size="xs"
                onClick={() => addEvent(row.original, delegates, price)}
                colorScheme={
                    isSelected(row.original.id, row.original.provider, row.original.course?.title)
                        ? 'green'
                        : 'blue'
                }
                isDisabled={row.original.availability === 0}
            >
                {console.log('whats is event row', row)}
                {isSelected(row.original.id, row.original.provider, row.original.course?.title)
                    ? 'Added to booking'
                    : 'Add to booking'}
            </Button>
        </Flex>
    );
};

export default EventSearch;

import React, {
    useCallback,
    memo,
    cloneElement,
    Children,
    isValidElement,
    useState,
    useEffect,
} from 'react';
import { useQuery } from '@builtbypixel/nucleus';
import { Form } from '../../components/Form';
import {
    Flex,
    Button,
    Text,
    Box,
    Skeleton,
    Stack,
    IconButton,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { useGet, useHttp } from '../../hooks';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';
import InfoBar from './InfoBar';
import DeveloperTools from './DeveloperTools';
import MoreOptions from './MoreOptions';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { formAtom } from '../../state/form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RiCodeSSlashLine } from 'react-icons/ri';

const SubmitButton = memo(({ setup }) => {
    const params = useParams();
    const { loading } = useRecoilValue(formAtom);
    const isEdit = params.type === 'edit';
    const { submit } = useFormContext();

    return (
        <Button
            variant="success"
            color="white"
            onClick={() => submit()}
            isLoading={loading}
            loadingText="Submitting "
        >
            {isEdit ? `Save ${setup.singular}` : `Submit ${setup.singular}`}
        </Button>
    );
});

const FormColumn = memo((props) => {
    const { isEdit, data, children, customSubmit } = props;

    return (
        <Flex overflowX="hidden" flex={1} align="center" direction="column">
            {isEdit && !customSubmit ? (
                data ? (
                    children
                ) : (
                    <Box w="90%" h="100%" maxWidth={600} mt={10}>
                        <Skeleton variant="rect" h="100px" mb="5px" />
                        <Skeleton variant="rect" mb="5px" />
                        <Skeleton h="30px" mb="5px" />
                        <Skeleton mb="5px" />
                        <Skeleton variant="rect" mb="5px" />
                        <Skeleton h="30px" mb="5px" />
                    </Box>
                )
            ) : (
                Children.map(children, (child) => {
                    if (isValidElement(child)) {
                        return cloneElement(child, props);
                    }
                    return child;
                })
            )}
        </Flex>
    );
});

const EditView = memo((props) => {
    const {
        children,
        setup,
        isFullWidth,
        noPadding,
        validationSchema,
        ignoreValues,
        defaultValues,
        hideSubmit,
        hideHeaderButtons = false,
        viewOnly,
        id: entryId,
        onSubmit: customSubmit = false,
    } = props;
    const params = useParams();
    const Http = useHttp();
    const isEdit = params.type === 'edit';
    const isCreate = params.type === 'create';
    const [devTools, setDevTools] = useState(false);
    const formOnly = useQuery('popup');
    const history = useHistory();
    const [formState, setFormState] = useRecoilState(formAtom);

    const toast = useToast();

    const { data } = useSWR(
        params.type === 'edit' ? `${setup.endpoint}/${entryId ? entryId : params.id}` : null,
        useGet,
    );

    React.useState(() => {
        setFormState((old) => ({ ...old, errors: null, success: false }));
    }, []);

    /* eslint-disable */
    const onSubmit = useCallback((values) => {
        if (!customSubmit) {
            setFormState((old) => ({ ...old, loading: true }));
            if (isEdit) {
                Http.put(`${setup.endpoint}/${entryId ? entryId : params.id}`, values)
                    .then((res) => {
                        setFormState((old) => ({
                            ...old,
                            loading: false,
                            errors: null,
                        }));
                        toast({
                            status: 'success',
                            title: 'Success',
                            position: 'bottom-right',
                            description: 'Entry has been saved successfully',
                        });

                        if (formOnly) {
                            //thisis always true so commenting out for now
                            //   window.close();
                        }
                    })
                    .catch((err) => {
                        setFormState((old) => ({
                            ...old,
                            loading: false,

                            success: false,
                        }));
                        toast({
                            status: 'error',
                            title: 'Error',
                            position: 'bottom-right',
                            description: 'There was an error saving this entry',
                        });
                    });
            }
            if (isCreate) {
                Http.post(setup.endpoint, values)
                    .then(() => {
                        setFormState((old) => ({
                            ...old,
                            loading: false,
                            errors: null,
                        }));
                        history.push(`/${setup.model}`);
                        toast({
                            status: 'success',
                            title: 'Success',
                            position: 'bottom-right',
                            description: 'Entry has been saved successfully',
                        });
                    })
                    .catch((err) => {
                        setFormState((old) => ({
                            ...old,
                            loading: false,

                            success: false,
                        }));
                        toast({
                            status: 'error',
                            title: 'Error',
                            position: 'bottom-right',
                            description: 'There was an error saving this entry',
                        });
                    });
            }
        } else {
            customSubmit(values);
        }
    }, []);

    useEffect(() => {
        if (formState.errors) {
            setTimeout(() => {
                setFormState((old) => ({ ...old, errors: null }));
            }, 7000);
        }
    }, [formState.errors]);

    useEffect(() => {
        if (formState.success === true) {
            setTimeout(() => {
                setFormState((old) => ({ ...old, success: false }));
            }, 7000);
        }
    }, [formState.success]);

    if (!data && isEdit) {
        return (
            <Flex w="100%" h="500px" align="center" justify="center">
                <Spinner w="50px" h="50px" />
            </Flex>
        );
    }

    return (
        <Form
            data={data && data.data}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            ignoreValues={ignoreValues}
            defaultValues={defaultValues}
        >
            <Flex
                align="center"
                direction="column"
                m={noPadding ? '-20px' : '0px'}
                bg="rgba(0,0,0,0.05)"
                minHeight="100vh"
                maxWidth="100%"
            >
                <>
                    <Flex
                        w="100%"
                        align="center"
                        px={2}
                        h={50}
                        flexShrink={0}
                        flexGrow={0}
                        borderBottom="1px"
                        borderColor="gray.200"
                        pos="relative"
                        zIndex={1000}
                        bg="white"
                        position="sticky"
                        top={0}
                        left={0}
                    >
                        {!hideHeaderButtons && (
                            <Button
                                variant="ghost"
                                colorScheme="gray"
                                size="sm"
                                opacity={0.7}
                                mr={2}
                                onClick={() => history.push(`/${setup.model}`)}
                                leftIcon={<MdKeyboardArrowLeft fontSize="30px" />}
                            >
                                View all {setup.model}
                            </Button>
                        )}
                        {!hideHeaderButtons && (
                            <Text
                                as="h1"
                                fontSize="lg"
                                fontWeight="semibold"
                                ml="25px"
                                display={{ base: 'none', md: 'inline-block' }}
                            >
                                {isEdit ? 'Edit' : 'Create'} {setup.singular}{' '}
                                {params.id && `#${params.id} `}
                                {data?.data?.first_name && data?.data?.first_name}{' '}
                                {data?.data?.last_name && data?.data?.last_name}
                            </Text>
                        )}
                        <Stack ml="auto" isInline spacing="10px" mr="10px">
                            {!hideHeaderButtons && <MoreOptions setup={setup} />}
                            {hideSubmit !== true && <SubmitButton setup={setup} />}
                            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                                <IconButton
                                    onClick={() => setDevTools(!devTools)}
                                    rounded="lg"
                                    fontSize="16px"
                                    mr="0px"
                                >
                                    <RiCodeSSlashLine />
                                </IconButton>
                            )}
                        </Stack>
                    </Flex>
                </>

                <ErrorMessage />
                <SuccessMessage />
                <Flex flex={1} pb="0px" w="100%" flexGrow={0} justify="flex-start">
                    <FormColumn
                        data={data && data}
                        isEdit={isEdit}
                        children={children}
                        isFullWidth={isFullWidth}
                        customSubmit={customSubmit}
                    />
                    <InfoBar />

                    {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                        <Flex
                            direction="column"
                            w="100%"
                            h="100%"
                            overflowY="scroll"
                            maxWidth="350px"
                            flexGrow={0}
                            bg="#1a1a1e"
                            position="fixed"
                            right={0}
                            zIndex={8000}
                            top={0}
                            willChange="transform"
                            transition="all 0.3s ease"
                            transform={devTools ? 'translateX(0%)' : 'translateX(100%)'}
                        >
                            {devTools && (
                                <DeveloperTools setDevTools={setDevTools} devTools={devTools} />
                            )}
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Form>
    );
});

EditView.defaultProps = {
    isFullWidth: true,
};

export default EditView;

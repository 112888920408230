import React, { useEffect, useState } from 'react';
import { Field, useGet, useQuery } from '@builtbypixel/nucleus';
import { Text, Flex, Grid } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

const EventSettings = () => {
    const query = useQuery();
    const courseId = query.get('courseId');
    const providerId = query.get('provider');
    const { type } = useParams();
    const { setValue, getValues } = useFormContext();

    useEffect(() => {
        if (courseId) {
            setValue('course', { id: courseId });
        }
        if (providerId) {
            setValue('provider', { id: providerId });
        }
    }, [courseId, providerId, setValue]);

    const provider = useWatch({
        name: 'provider',
    });
    const course = useWatch({
        name: 'course',
    });

    const { data: providers } = useSWR('/providers?pagination=999&page=1', useGet);

    // Fetch the courses when provider is set
    const { data: courses } = useSWR(provider ? `/courses/provider/${provider.id}` : null, useGet);

    // Fetch the delivery methods of the course
    const { data: styles } = useSWR(course ? `/courses/${course.id}/learning-styles` : null, useGet);

    return (
        <Flex direction="column" bg="white" rounded="md" p="15px">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Event Settings
            </Text>
            <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                <Field
                    name="published"
                    component="switch"
                    label="Published"
                    size="lg"
                    defaultChecked={true}
                    defaultValue={type === 'create' ? true : getValues('published')}
                />
                <Field
                    name="is_marble"
                    component="switch"
                    label="Is Marble"
                    size="lg"
                    defaultChecked={false}
                    defaultValue={type === 'create' ? false : getValues('is_marble')}
                />
            </Grid>
            <Grid templateColumns="1fr 1fr 1fr" gap="30px">
                <Field
                    name="provider"
                    component="select"
                    labelKey="name"
                    valueKey="id"
                    label="Provider"
                    options={providers?.data}
                    rules={{ required: true }}
                />
                <Field
                    name="course"
                    isDisabled={!provider}
                    component="select"
                    labelKey="title"
                    valueKey="id"
                    label="Course"
                    options={courses?.data}
                    rules={{ required: true }}
                />
                <Field
                    name="learningItem"
                    component="select"
                    labelKey="name"
                    valueKey="id"
                    label="Delivery Method"
                    options={styles?.filter((e) => e.active === true)}
                    rules={{ required: true }}
                    isDisabled={!course}
                />
            </Grid>

            <DeliveryMethodFields />

            <CodeAndPrice course={course} />
        </Flex>
    );
};
const DeliveryMethodFields = () => {
    const method = useWatch({
        name: 'learningItem',
    });
    const location = useWatch({
        name: 'locations',
    });

    const { data: locations } = useSWR(method && !method.continuous ? `/locations` : null, useGet);

    // useEffect(() => {
    //     if (method) {
    //         if (!method.continuous) {
    //             // setValue('zoom_link', '');
    //             setValue('tile_link', '');
    //         }
    //         if (method.continuous) {
    //             setValue('location', null);
    //             setValue('venue', null);
    //             setValue('tile_link', '');
    //         }
    //         if (!method.elearning) {
    //             setValue('tile_link', '');
    //         }
    //     }
    // }, [method, setValue]);

    const isNotOnline = (method) => {
        if (method.name === 'E-Learning') {
            return false;
        }
        if (method.name === 'Live Remote') {
            return false;
        }
        return true;
    };

    return method ? (
        <Grid templateColumns={'1fr 1fr 1fr'} gap="30px">
            {method.name === 'Live Remote' && (
                <Field
                    name="zoom_link"
                    component="text"
                    placeholder="Enter the zoom link for this event"
                    label="Zoom Link"
                    rules={{ required: method.continuous ? true : false }}
                />
            )}

            {method.name === 'E-Learning' && (
                <Field
                    name="tile_link"
                    component="text"
                    placeholder="Enter the e-Learning software link for this event"
                    label="Software Link"
                    rules={{ required: method.elearning ? true : false }}
                />
            )}
            {isNotOnline(method) && (
                <>
                    <Field
                        name="locations"
                        component="select"
                        labelKey="location_name"
                        valueKey="id"
                        label="Location"
                        options={locations?.data}
                        rules={{ required: true }}
                    />

                    <Field
                        name="venues"
                        component="select"
                        labelKey="title"
                        valueKey="id"
                        label="Venue"
                        options={location?.venues}
                        isDisabled={!location}
                        rules={{ required: true }}
                    />
                </>
            )}
            {!method.elearning && (
                <Field
                    name="availability"
                    component="number"
                    defaultValue={1}
                    label="Spaces Available"
                    rules={{ required: !method.elearning ? true : false }}
                />
            )}
        </Grid>
    ) : null;
};

const CodeAndPrice = () => {
    const { type } = useParams();
    const { setValue, getValues } = useFormContext();
    const [defaultPrice, setDefaultPrice] = useState(0);
    const [bundlePrice, setBundlePrice] = useState(0);

    const { data: eventStatus } = useSWR('/event-statuses', useGet);
    const provider = useWatch({
        name: 'provider',
    });
    const learningItem = useWatch({
        name: 'learningItem',
    });

    useEffect(() => {
        if (learningItem && type === 'create') {
            setValue('price', parseFloat(learningItem?.price));
            setValue('bundle_price', parseFloat(learningItem?.bundle_price));
            setValue('access_time', learningItem.access_time);
            setValue('time_to_complete', learningItem.time_to_complete);
            if (learningItem.name !== 'E-Learning') {
                if (learningItem.duration) {
                    if (learningItem.duration.start_time) {
                        setValue('start_time', learningItem.duration.start_time);
                    }
                    if (learningItem.duration.end_time) {
                        setValue('end_time', learningItem.duration.end_time);
                    }
                }
            }
            if (learningItem.name !== 'E-Learning') {
                setValue(
                    'availability',
                    learningItem?.availability ? parseInt(learningItem?.availability) : 1,
                );
            } else {
                setValue('availability', 9999);
            }
            setDefaultPrice(parseFloat(learningItem?.price), 'learningItem');
            setBundlePrice(parseFloat(learningItem?.bundle_price), 'learningItem');
        }
    }, [learningItem]);

    useEffect(() => {
        if (type === 'edit') {
            const editPrice = getValues('price');
            const editBundlePrice = getValues('bundle_price');

            setDefaultPrice(parseFloat(editPrice));
            setBundlePrice(parseFloat(editBundlePrice));
        }
    }, []);

    const handleSetPrice = (val) => {
        setDefaultPrice(val);
        setValue('price', val);
    };

    const handleBundleSetPrice = (val) => {
        setBundlePrice(val);
        setValue('bundle_price', val);
    };
    const isBundle = useWatch({
        name: 'is_bundle',
    });
    return (
        <>
            <Grid templateColumns="1fr 1fr" gap="30px">
                <Field
                    name="event_code"
                    component="text"
                    placeholder="..."
                    label="Event Code"
                    defaultValue={provider && provider.prefix ? `${provider?.prefix}/` : ''}
                    rules={{ required: true }}
                    isDisabled={!provider}
                />
                <Field
                    name="event_status"
                    component="select"
                    labelKey="name"
                    valueKey="id"
                    label="Event Status"
                    options={eventStatus?.data}
                    rules={{ required: true }}
                />
            </Grid>
            <Grid templateColumns="1fr 100px 1fr" gap="30px">
                <Field
                    name="price"
                    component="number"
                    label="Event Price (exc VAT)"
                    value={defaultPrice}
                    onChange={handleSetPrice}
                    step={0.01}
                    rules={{ required: true }}
                />
                <Field component="switch" name="info_needed" label="Information needed?" />

                <Field component="switch" name="is_bundle" label="Add bundle price?" />
                {isBundle && (
                    <Field
                        name="bundle_price"
                        component="number"
                        step={0.01}
                        label="Bundle Price (exc VAT)"
                        value={bundlePrice}
                        onChange={handleBundleSetPrice}
                    />
                )}
            </Grid>
        </>
    );
};

export default EventSettings;

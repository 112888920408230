import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

const Form = (props) => {
    const { children, onSubmit, data, validationSchema, defaultValues } = props;

    const methods = useForm({
        shouldUnregister: false,
        mode: 'onSubmit',
        defaultValues: data ? data : defaultValues ? defaultValues : {},
        reValidateMode: 'onBlur',
        shouldFocusError: false,

        resolver: validationSchema ? yupResolver(validationSchema) : null,
    });

    const submitForm = async () => {
        async function send() {
            // get filled values
            const val = await methods.getValues();
            // trigger validation on fields
            const validation = await methods.trigger();

            if (Object.keys(methods.formState.errors).length === 0 && validation) {
                onSubmit(val);
            }
        }

        send();
    };
    // (onSubmit ? onSubmit(data) : console.log(data))

    /* eslint-disable */
    React.useEffect(() => {
        methods.reset(data);
        return () => {
            methods.reset(null);
        };
    }, [data]);

    return (
        <FormProvider {...methods} submit={submitForm} data={data}>
            <Flex
                as="form"
                onSubmit={(e) => e.preventDefault()}
                w="100%"
                justify="center"
                direction="column"
                noValidate
            >
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { submit: submitForm });
                    }
                    return child;
                })}
            </Flex>
        </FormProvider>
    );
};

export default Form;

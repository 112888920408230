import React, { useState } from 'react';
import { LinkButton, ListWrapper, useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import { Button, useToast } from '@chakra-ui/react';
import ExportCustomer from './../../components/ExportCustomer/ExportCustomer';

const extraMenu = () => {
    return <ExportCustomer type="business" />;
};
const List = () => {
    const baseUrl = process.env.REACT_APP_LOGIN_AS;

    const Http = useHttp();
    const toast = useToast();

    // for the reset password
    const [loading, setLoading] = useState(null);

    // for the login as customer
    const [loadingCustomer, setLoadingCustomer] = useState(null);

    const sendResetPasswordEmail = (email) => {
        setLoading(email);
        Http.post('password-reset', { email })
            .then(() => {
                setLoading(false);
                toast({
                    status: 'success',
                    title: 'Password reset link sent',
                    position: 'top',
                });
            })
            .catch(() => {
                setLoading(false);
                toast({
                    status: 'error',
                    message: 'Error sending reset password link',
                    position: 'top',
                });
            });
    };

    const loginAsCustomer = (email) => {
        setLoadingCustomer(email);
        Http.post('login-as-user', { email })
            .then((res) => {
                setLoadingCustomer(false);
                setTimeout(() => {
                    window.open(baseUrl + '/dashboard?loginAs=' + res.data.success.token);
                }, 500);
            })
            .catch(() => {
                setLoadingCustomer(false);
                toast({
                    status: 'error',
                    message: 'Error logging into users account',
                    position: 'top',
                });
            });
    };

    const columns = [
        {
            Header: 'Company Name',
            accessor: 'company_name',
            Cell: ({ row }) => (
                <LinkButton target="_blank" to={`${setup.model}/edit/${row.original.id}`}>
                    <Button variant="link" colorScheme="blue" rounded="md" size="sm">
                        {row.original.company_name
                            ? row.original.company_name
                            : 'No company name provided'}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Account Number',
            accessor: 'account_number',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Reset Password',
            Cell: ({ row }) => (
                <Button
                    isLoading={loading === row.original.email ? true : false}
                    variant="solid"
                    isDisabled={loading === row.original.email ? true : false}
                    rounded="md"
                    size="sm"
                    onClick={() => sendResetPasswordEmail(row.original.email)}
                >
                    Send Reset Password Email
                </Button>
            ),
        },
        {
            Header: 'Login As Customer',
            Cell: ({ row }) => (
                <Button
                    isLoading={loadingCustomer === row.original.email ? true : false}
                    isDisabled={loadingCustomer === row.original.email ? true : false}
                    variant="outline"
                    rounded="md"
                    size="sm"
                    onClick={() => loginAsCustomer(row.original.email)}
                >
                    Login As Customer
                </Button>
            ),
        },
    ];

    return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;

import React, { useEffect, useState } from 'react';
import { useHttp } from '@builtbypixel/nucleus';
import CalendarComponent from '../../../components/Calendar';
//import CalendarComponent from '../../../containers/Trainers/components/Availability/EventCalendar';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { BsCalendar } from 'react-icons/bs';
import { FaPoundSign, FaUser } from 'react-icons/fa';
import moment from 'moment';

const EventView = () => {
    const [data, setData] = useState([]);
    const [events, setEvents] = useState([]);

    const [modalData, setModalData] = useState(null);

    const Http = useHttp();

    useEffect(() => {
        Http.get(`/all-trainers-availability`).then((res) => {
            setData(res.data.data);
        });
    }, [Http]);
    console.log(modalData, 'modaldata');
    const ModalContent = () => {
        return (
            <Box>
                {/* <--------------------[[ TRAINER START]]--------------------> */}
                {/* <Box
          bg="#03bda1"
          color="white"
          fontWeight="bold"
          px="1em"
          mb="0.5em"
          w="100%"
        >
          Trainer
        </Box> */}

                {/* <Text>{`${modalData.trainers[0].name}`}</Text> */}
                {/* <--------------------[[ TRAINER START]]--------------------> */}
                {/* <--------------------[[ DATES START]]--------------------> */}
                <Flex justify="space-between">
                    <Flex direction="column">
                        <Box bg="#03bda1" color="white" fontWeight="bold" px="1em" mb="0.5em">
                            Start Date
                        </Box>
                        <Flex align="center" justify="center">
                            <BsCalendar></BsCalendar>
                            <Text ml="1em">{moment(modalData.start).format('DD-MM-YYYY')}</Text>
                        </Flex>
                    </Flex>

                    <Flex direction="column">
                        <Box bg="#03bda1" color="white" fontWeight="bold" px="1em" mb="0.5em">
                            End Date
                        </Box>

                        <Flex align="center" justify="center">
                            <BsCalendar></BsCalendar>
                            <Text ml="1em">{moment(modalData.end).format('DD-MM-YYYY')}</Text>
                        </Flex>
                    </Flex>
                </Flex>
                {/* <--------------------[[ DATES END]]--------------------> */}

                <Flex direction="column" mt="1em">
                    {/* <--------------------[[ EVENT END]]--------------------> */}
                    <Box bg="#03bda1" color="white" fontWeight="bold" px="1em" mb="0.5em" w="100%">
                        Event Details
                    </Box>
                    <Flex justify="space-between">
                        <Text>Awarding Body</Text>
                        <Text align="center">{`${modalData.provider.name}`}</Text>
                    </Flex>
                    <Divider />
                    <Flex justify="space-between">
                        <Text>Event Code</Text>
                        <Text align="center">{`${modalData.event_code}`}</Text>
                    </Flex>
                    <Divider />
                    <Flex justify="space-between">
                        <Text>Course Name</Text>
                        <Text align="center">{`${modalData.course.title}`}</Text>
                    </Flex>
                    <Divider />
                    <Flex justify="space-between">
                        <Text>Delivery Method</Text>
                        <Text align="center">{`${modalData.learningItem.name}`}</Text>
                    </Flex>
                    <Divider />
                    <Flex justify="space-between">
                        <Text>Delegates</Text>
                        <Flex align="center">
                            <FaUser />

                            <Text ml="0.2em">{`${modalData.delegate_count ?? 0}`}</Text>
                        </Flex>
                    </Flex>
                    <Divider />
                    <Flex mt="1em" justify="space-between">
                        <Text>Price</Text>

                        <Flex align="center">
                            <FaPoundSign />
                            {`${modalData.price}`}
                        </Flex>
                    </Flex>
                    <Divider />
                    {/* <--------------------[[ EVENT END]]--------------------> */}

                    {/* <--------------------[[ COSTS START]]--------------------> */}
                    <Box bg="#03bda1" color="white" fontWeight="bold" px="1em" my="0.5em" w="100%">
                        Costs
                    </Box>

                    {modalData.trainers[0].prices.map((price) => {
                        return (
                            <>
                                <Flex justify="space-between">
                                    <Text>{price.type.name}</Text>
                                    <Flex align="center">
                                        <FaPoundSign />
                                        {`${price.price}`}
                                    </Flex>
                                </Flex>
                                <Divider />
                            </>
                        );
                    })}
                    {/* <--------------------[[ COSTS END]]--------------------> */}
                </Flex>
            </Box>
        );
    };
    const ModalTitle = () => {
        return <Box>{<h1>{modalData.title}</h1>}</Box>;
    };

    useEffect(() => {
        let allEvents = [];
        data?.length > 0 &&
            data.map((trainer) => {
                return (
                    trainer?.trainer_events?.length > 0 &&
                    trainer.trainer_events.map((event) => {
                        if (event.length === 0) return;
                        else {
                            if (event.dates.length > 0) {
                                event.dates.map((singleDate) => {
                                    let eventObj = {
                                        ...event,
                                        start_date: new Date(singleDate.start),
                                        end_date: new Date(singleDate.end),
                                        title: `${trainer.first_name} ${trainer.last_name} (${event.event_code})`,
                                    };
                                    allEvents.push(eventObj);
                                });
                            }
                        }
                    })
                );
            });
        console.log(allEvents, '!!!!! all events');

        setEvents(allEvents);
    }, [data]);
    console.log(events, '!!!!! just events');

    return (
        <CalendarComponent
            events={events}
            setModalData={setModalData}
            modalContent={<ModalContent></ModalContent>}
            modalTitle={<ModalTitle></ModalTitle>}
        ></CalendarComponent>
    );
};

export default EventView;

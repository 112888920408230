import React from 'react';
import {
    Flex,
    Text,
    Popover,
    Button,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiOutlineUserGroup } from 'react-icons/hi';

const EventPopup = ({ event }) => {
    return (
        <Popover isLazy lazyBehavior="unmount">
            <Flex
                w="100%"
                rounded="md"
                bg="blue.100"
                justify="flex-end"
                align="center"
                direction="column"
                p="4px"
                border="2px"
                borderColor="blue.300"
                pt="20px"
            >
                <Text
                    textAlign="center"
                    fontSize="11px"
                    mb="4px"
                    fontWeight="bold"
                    color="blue.600"
                >
                    {event.course.title}
                </Text>
                <PopoverTrigger>
                    <Button colorScheme="blue" isFullWidth size="sm">
                        View
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />

                    <PopoverBody>
                        <Flex p="10px" direction="column">
                            <Text fontWeight="bold" mb="10px" fontSize="15px">
                                {event.course.title}
                            </Text>
                            {event.location ? (
                                <Text>{event.locations?.location_name}</Text>
                            ) : (
                                <Text>{event.learningItem.name}</Text>
                            )}
                            {event.venues && event.venues.length !== 0 && (
                                <Text>{event.venues[0].title}</Text>
                            )}
                            <Flex align="center" my="15px">
                                <HiOutlineUserGroup fontSize="22px" />{' '}
                                <Text ml="15px">
                                    <Text as="span" fontWeight="bold">
                                        Delegates Booked:
                                    </Text>{' '}
                                    {event.delegate_count ?? 0}
                                </Text>
                            </Flex>
                            <Link to={`/events/edit/${event.id}`}>
                                <Button isFullWidth size="sm" colorScheme="blue">
                                    View Event
                                </Button>
                            </Link>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Flex>
        </Popover>
    );
};

export default EventPopup;

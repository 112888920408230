import React, { useState, useEffect } from 'react';
import { Text, Flex, Box, Spinner } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { useGet, useHttp } from '@builtbypixel/nucleus';

import { useParams } from 'react-router';
import useSWR from 'swr';

import Table from '../../../../../components/common/CustomTable';

const LoaderComponent = () => (
    <Flex
        p={5}
        position="absolute"
        top="30px"
        bg="global.elementBg"
        left={0}
        w="100%"
        h="100%"
        justify="center"
        align="center"
        zIndex={100}
        opacity={0.6}
    >
        <Spinner color="primary" size="30px" />
    </Flex>
);

const TableView = ({ data }) => {
    const { id } = useParams();

    const [pagination, setPagination] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const Http = useHttp();


    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Event',
            accessor: '',
            Cell: ({ row }) => <>{row.original.lines[0]?.cancellation.event.course}</>,
        },
        {
            Header: 'Delegates Cancelled',
            Cell: ({ row }) => (
                <>
                    {row.original.lines?.length}
                </>
            )
        },
        {
            Header: 'Refund Amount',
            Cell: ({ row }) => <>{`£${row.original.lines[0]?.cancellation.refund_amount}`}</>,
        },
        {
            Header: 'Status',
            Cell: ({ row }) => <>{`${row.original.lines[0]?.cancellation.status.name}`}</>,
        },
        {
            Header: 'Original Price',
            Cell: ({ row }) => <>{`£${row.original.lines[0]?.cancellation.original_value}`}</>,
        },
        {
            Header: 'Refund %',
            Cell: ({ row }) => <>{`${row.original.lines[0]?.cancellation.refund_percent}%`}</>,
        },
        {
            Header: 'Total Vat',
            Cell: ({ row }) => <>{`£${row.original.vat}`}</>,
        },
        {
            Header: 'Total',
            Cell: ({ row }) => <>{`£${row.original.total}`}</>,
        },
        {
            Header: 'Download',
            Cell: ({ row }) => (
                <Button onClick={() => download_credit_note(row.original.id)} variant="link" colorScheme="blue" rounded="md">
                    Download Credit Note
                </Button>
            ),
        }
    ];

    const download_credit_note = (id) => {
        Http.get(`/generate-credit-note/${id}`,{responseType: 'blob'}).then((response)=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            console.log(url, 'url');
            const link = document.createElement('a');
            console.log(link, 'link');
            link.href = url;
            link.setAttribute('download', `credit-note-${id}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch((err)=>{
            console.log(err);
        })
    }

    const renderRowSubComponent = ({ row }) => {

        const columns = [
            {
                Header: 'First Name',
                accessor: 'first_name',
                // Cell: ({ row }) => <>{moment(row.original.created_at).format('DD/MM/YYYY')}</>,
            },
            {
                Header: 'Last Name',
                accessor: 'last_name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Delegate Select',
                Cell: ({ row }) => (
                    <Flex>

                    </Flex>
                ),
            },
        ];

        return (
            <div
                style={
                    row.isExpanded && {
                        background: '#bedaf6',
                        transform: 'scale(1)',
                        // marginLeft: '2em',
                    }
                }
            >
                {/* {row.original.delegates_count > 0 && ( */}
                    <Table
                        columns={columns}
                        data={{ data: row.original.lines[0].cancellation }}
                        hidePagination={true}
                        canSelect={false}
                    ></Table>
                {/* )} */}
            </div>
        );
    }

    return (
        <>
            {!data ? (
                <LoaderComponent />
            ) : data.length !== 0 ? (
                <Table
                    columns={columns}
                    data={data}
                    // renderRowSubComponent={renderRowSubComponent}
                />
            ) : (
                <Text>No data available.</Text>
            )}
        </>
    );
};

export default TableView;

import React, { useEffect, useState } from 'react';
import Cloudinary from './Cloudinary';
import Standard from './Standard';
import { Flex, Button, Select, Text } from '@chakra-ui/react';
const FileUpload = (props) => {
    const [fileType, setFileType] = useState('file');

    // console.log('hi props');
    console.log(`File/index.jsx::props`, { props });
    return (
        <>
            {fileType && (
                <>
                    <Flex direction={'column'} w="200px" mb="20px">
                        <Text fontWeight={'medium'} mb="10px">
                            Select Upload type
                        </Text>
                        <Select onChange={(e) => setFileType(e.target.value)} value={fileType}>
                            <option value="file">Standard File</option>
                            <option value="cloudinary">Cloudinary</option>
                        </Select>
                    </Flex>
                    {fileType === 'cloudinary' ? (
                        <Cloudinary {...props} />
                    ) : (
                        <Standard {...props} />
                    )}
                </>
            )}
        </>
    );
};

export default FileUpload;

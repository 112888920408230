import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Field } from '@builtbypixel/nucleus';

const UploadedDocuments = () => {
    return (
        <Flex w="100%" direction="column" bg="white" rounded="md" p="15px" align="flex-start">
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Trainer Documents
            </Text>
            <Field
                label="Add documents for this trainer to view."
                name="trainer_documents"
                component="repeater"
                required={false}
                help="These are documents that are specific to this trainer"
                fieldArray={[
                    {
                        name: 'name',
                        component: 'text',
                        label: 'File name',
                    },
                    {
                        name: 'document',
                        component: 'file',
                        label: 'Upload file',
                    },
                ]}
            />
        </Flex>
    );
};

export default UploadedDocuments;

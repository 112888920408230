import React, { lazy } from 'react';
import { Flex, Tabs, TabPanels, TabPanel, Box, Alert, AlertIcon, Text } from '@chakra-ui/react';
import EditMenu from './EditMenu';
import EventSettings from './components/EventSettings';
import Dates from './components/Dates';
import TrainerAvailability from './components/TrainerAvailability';
import Discounts from './components/Discounts';
import TrainerDocuments from './components/TrainerDocuments';

import Delegates from './components/Delegates';
import Bookings from './components/Bookings';
import EventCosts from './components/EventCosts';
import ElearningTimes from './components/ElearningTimes';
import LearnerUploads from './components/LearnerUploads';
import ProvisionalDelegates from './components/ProvisionalDelegates';
import PreorderDelegates from './components/PreorderDelegates';
import TrainerDetails from './components/TrainerDetails';
import MiscCosts from './components/MiscCosts/Index';
import { useParams } from 'react-router-dom';

import { useWatch } from 'react-hook-form';
import CancelEvent from './components/CancelEvent';

const EditForm = ({ id }) => {
    const { type } = useParams();
    const method = useWatch({
        name: 'learningItem',
    });
    const status = useWatch({
        name: 'event_status',
    });

    const course = useWatch({
        name: 'course',
    });

    const learningItem = useWatch({
        name: 'learningItem',
    });

    const locations = useWatch({
        name: 'locations',
    });

    return (
        <Flex w="100%" minHeight="100vh" bg="white">
            <Tabs flex={1} minHeight="100vh">
                <Flex justify="flex-end" borderBottom="1px" borderColor="gray.200">
                    <Text
                        as="h1"
                        fontSize="lg"
                        fontWeight="semibold"
                        ml="25px"
                        mr="1em"
                        py="0.5em"
                        display={{ base: 'none', md: 'inline-block' }}
                    >{`${course?.title} | ${learningItem?.name}  ${locations?.location_name ? '|' + ' ' + locations?.location_name : ''
                        }`}</Text>
                </Flex>
                <EditMenu />
                <TabPanels
                    bg="blackAlpha.100"
                    px="0px"
                    minHeight="100vh"
                    key={`render-panels-${status?.length}`}
                >
                    <TabPanel>
                        {status && status.name === 'Cancelled' && (
                            <Alert status="error">
                                <AlertIcon /> This event has been cancelled
                            </Alert>
                        )}
                        <EventSettings />
                        {method && method.elearning ? (
                            <ElearningTimes />
                        ) : (
                            <>
                                <Dates /> {type === 'create' && <TrainerAvailability />}
                                {status && status.name !== 'Cancelled' && <CancelEvent />}
                            </>
                        )}
                    </TabPanel>
                    <TabPanel>
                        <Discounts />
                    </TabPanel>
                    <TabPanel>
                        <Delegates id={id} />
                    </TabPanel>

                    <TabPanel>
                        <ProvisionalDelegates id={id} />
                    </TabPanel>
                    <TabPanel>
                        <PreorderDelegates id={id} />
                    </TabPanel>

                    <TabPanel>
                        <Bookings id={id} />
                    </TabPanel>
                    <TabPanel>
                        <TrainerDocuments type="trainer_documents" />
                    </TabPanel>
                    <TabPanel>
                        <TrainerDocuments type="trainer_completion_uploads" />
                    </TabPanel>
                    <TabPanel>
                        <TrainerDetails />
                    </TabPanel>
                    <TabPanel>
                        <LearnerUploads id={id} />
                    </TabPanel>
                    <TabPanel>
                        <MiscCosts id={id} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Flex
                flex={1}
                maxWidth="300px"
                minHeight="100vh"
                bg="white"
                borderLeft="1px"
                borderColor="gray.200"
            >
                <Box w="100%" maxHeight="100vh" overflowY="auto">
                    <EventCosts />
                </Box>
            </Flex>
        </Flex>
    );
};

export default EditForm;

import React, { useState } from 'react';
import {
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Modal,
    Button,
    ModalHeader,
    ModalCloseButton,
    Flex,
    Box,
    useDisclosure,
} from '@chakra-ui/react';
import Select from 'react-select';

import { Field } from '@builtbypixel/nucleus';
import { useFormContext } from 'react-hook-form';

const BookingTypeModal = ({ isOpen, onOpen, onClose, setBrokerBooking }) => {

    return (
        <Flex>
            <Modal size="sm" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minWidth={{ base: '100%', xl: '650px' }}>
                    <ModalCloseButton />
                    <ModalHeader>Booking Type</ModalHeader>
                    <ModalBody>
                        <Box style={{ marginBottom: 30 }}>
                            <Select
                                onChange={(e) => setBrokerBooking(e ? e.value : null)}
                                isClearable
                                options={[
                                    {
                                        id: 1,
                                        value: 'broker',
                                        label: 'Broker',
                                    },
                                    {
                                        id: 2,
                                        value: 'normal',
                                        label: 'Normal',
                                    },
                                ]}
                                placeholder="Select Booking Type"
                            ></Select>
                        </Box>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button onClick={() => onClose()}>Close</Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default BookingTypeModal;

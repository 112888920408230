import { Field } from '@builtbypixel/nucleus';
import { Stack } from '@chakra-ui/layout';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import useSWR from 'swr';
import { Box, Grid } from '@chakra-ui/react';
import FormSection from '../../../../components/FormSection';
import { useGet } from '../../../../Nucleus';

const Pricing = () => {
    const { data } = useSWR(`/price-types`, useGet);
    const { fields } = useFieldArray({ name: 'prices' });

    return (
        <FormSection title="Trainer Pricing">
            <Grid isInline spacing="20px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
                {fields.map((field, i) => (
                    <Stack isInline spacing="15px" key={field.id} maxWidth="400px">
                        <Box maxWidth="200px" w="100%">
                            <Field
                                name={`prices[${i}].type`}
                                component="select"
                                label="Type"
                                options={data ? data.data : []}
                                labelKey="name"
                                valueKey="id"
                            />
                        </Box>

                        <Field
                            name={`prices[${i}].price`}
                            component="text"
                            label="Price"
                            maxWidth="200px"
                        />
                    </Stack>
                ))}
            </Grid>
        </FormSection>
    );
};

export default Pricing;

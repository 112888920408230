import React, { useState, useEffect } from 'react';
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

const QuantityInput = ({ row }) => {
    const { setValue, getValues } = useFormContext();
    const [currentEvent, setCurrentEvent] = useState(null);
    const eventId = row.original.id;

    useEffect(() => {
        if (!currentEvent) {
            let event = getValues('cancel')?.events?.find((item) => item.id === eventId);
            setCurrentEvent(event);
        }
    }, [getValues('cancel')]);

    return (
        <NumberInput
            defaultValue={0}
            min={0}
            max={
                row.original.cancellations.length > 0
                    ? row.original.quantity - row.original.cancellations.length
                    : row.original.quantity
            }
            clampValueOnBlur={true}
            onChange={(valueString) => {
                let data = getValues('cancel');
                data.events[currentEvent.index].quantity = Number(valueString);

                data.events[currentEvent.index].selected = Number(valueString) > 0 ? true : false;
                setValue('cancel', data);
            }}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    );
};

export default QuantityInput;

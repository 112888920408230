import React from 'react'
import { Flex, Text, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const ListViewHeader = ({ setup, children }) => {
   return (
      <Flex
         align='center'
         px='20px'
         py='10px'
         borderBottom='1px'
         borderColor='gray.200'
      >
         <Text fontWeight='500' fontSize='1.3em'>
            {setup.title}
         </Text>
         <Flex flex={1}>{children}</Flex>
         <Flex ml='auto'>
            <Link to={`/${setup.model}/create`}>
               <Button variant='primary'>Create New</Button>
            </Link>
         </Flex>
      </Flex>
   )
}

export default ListViewHeader

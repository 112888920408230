import { Flex, Button, Text, Alert, AlertIcon, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHttp } from '../../../../Nucleus';
import { FiUserCheck } from 'react-icons/fi';
import { BiCheck } from 'react-icons/bi';
import { useParams } from 'react-router';
import CustomTable from '../../../../components/common/CustomTable';

const TrainerAvailability = () => {
    const { type } = useParams();
    const { getValues, setValue, watch } = useFormContext();

    const dates = watch('dates');
    const trainer_price_type = watch('trainer_price_type');
    const trainers = watch('trainers');

    const Http = useHttp();
    const [loading, setLoading] = useState(false);
    const [trainerResults, setTrainers] = useState(null);
    const [error, setError] = useState(false);

    const checkTrainerAvailability = () => {
        const location = getValues('locations');
        const dates = getValues('dates');
        let newDates = [];

        if (type === 'edit') {
            newDates = dates.map((obj) => ({ ...obj }));
            setValue('dates', newDates);
        }

        setLoading(true);
        Http.post(`/event-trainers`, {
            location: location,
            dates: type === 'create' ? dates : newDates,
        })
            .then((res) => {
                setTrainers(res.data.data);

                setLoading(false);
                setError(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
            });
    };

    const selectTrainer = (val) => {
        setValue('trainers', [val]);
    };

    const isTrainerSelected = (id) => {
        if (trainers) {
            const isId = (t) => t.id === id;
            const checkTrainer = trainers.some(isId);
            return checkTrainer;
        }
    };

    return (dates && dates.length !== 0 && type === 'create' && trainer_price_type) ||
        (dates && dates.length !== 0 && type === 'edit') ? (
        <Flex
            w="100%"
            direction="column"
            bg="white"
            rounded="md"
            p="15px"
            mt="25px"
            align="flex-start"
        >
            <Text fontSize="17px" fontWeight="bold" mb="15px">
                Trainer Availability
            </Text>
            <Button
                isLoading={loading}
                isDisabled={loading}
                loadingText="Search for trainers..."
                leftIcon={<FiUserCheck fontSize="22px" />}
                onClick={() => checkTrainerAvailability()}
                colorScheme="blue"
            >
                Check Trainer Availability
            </Button>

            {trainerResults && trainerResults.length === 0 && (
                <Alert status="error" mt="15px">
                    <AlertIcon />
                    No trainers found for the dates selected.
                </Alert>
            )}
            {error && (
                <Alert status="error" mt="15px">
                    <AlertIcon />
                    An error occurred searching for trainers
                </Alert>
            )}

            <Box mt="20px" w="100%">
                <CustomTable
                    data={trainerResults ? trainerResults : []}
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'name',
                            Cell: ({ value }) => <Text fontWeight="bold">{value}</Text>,
                        },
                        {
                            Header: 'Email',
                            accessor: 'email',
                        },
                        {
                            Header: 'Price',
                            accessor: 'price',
                            Cell: ({ value }) => <Text>£{value}</Text>,
                        },
                        {
                            Header: 'Action',
                            accessor: 'id',
                            Cell: ({ row }) => (
                                <Button
                                    variant={
                                        isTrainerSelected(row.original.id) ? 'solid' : 'outline'
                                    }
                                    colorScheme={
                                        isTrainerSelected(row.original.id) ? 'green' : 'blue'
                                    }
                                    size="sm"
                                    onClick={() => selectTrainer(row.original)}
                                    leftIcon={
                                        isTrainerSelected(row.original.id) ? (
                                            <BiCheck fontSize="18px" />
                                        ) : null
                                    }
                                >
                                    {isTrainerSelected(row.original.id) ? 'Selected' : 'Select'}
                                </Button>
                            ),
                        },
                    ]}
                />
            </Box>
        </Flex>
    ) : null;
};

export default TrainerAvailability;

import { useHttp } from '@builtbypixel/nucleus';
import { Flex, Text, Button, Thead, Table, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import CustomTable from '../../../../components/common/CustomTable';
import { useFormContext, useWatch } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import dayjs from 'dayjs';
import { PopConfirm } from '../../../../Nucleus';
import React, { useMemo, useCallback } from 'react';

const TrainerDocuments = ({ type }) => {
    const uploadsWatch = useWatch({ name: type });
    const Http = useHttp();

    const handleRemoveDocument = useCallback((url) => {
        Http.delete('trainer-event-document', { data: { url: url } }).then((res) => {
            window.location.reload();
        });
    }, [Http]);

    const columns = [
        {
            Header: 'File Name',
            accessor: 'name',
            Cell: ({ row }) =>
                row.original?.document[0]?.url
                    ? <a target="_blank" href={`${row.original?.document[0]?.url}`} rel="noreferrer">
                        <Button variant="link" colorScheme="blue" rounded="md">
                            <Text mr="10px">{row.original.name}</Text>
                        </Button>
                    </a>
                    : <Text mr="10px">{row.original.name}</Text>
        },
        {
            Header: 'Created At',
            accessor: 'created_at',
            Cell: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            Header: 'View',
            Cell: ({ row }) =>
                row.original?.document[0]?.url
                    ? (
                        <a target="_blank" href={`${row.original?.document[0]?.url}`} rel="noreferrer">
                            <Button variant="link" colorScheme="blue" rounded="md">
                                <FiExternalLink />
                            </Button>
                        </a>
                    )
                    : < Text mr="10px" > <i>Missing</i></Text >,
        },
        {
            Header: 'Delete',
            Cell: ({ row }) =>
                row.original?.document[0]?.url
                    ? <PopConfirm
                        onConfirm={() => handleRemoveDocument(row.original?.document[0]?.url)}
                        title="Are you sure you want to remove document?"
                    >
                        <Button variant="link" colorScheme="red" rounded="md">
                            <AiFillDelete />
                        </Button>
                    </PopConfirm>
                    : <Text mr="10px"><i>Missing</i></Text>
        },
    ];

    return (
        <>
            <Flex
                mt="25px"
                w="100%"
                direction="column"
                bg="white"
                rounded="md"
                p="15px"
                align="flex-start"
            >
                <CustomTable
                    data={uploadsWatch ?? []}
                    columns={columns}
                    documentStatuses={[]}
                />
            </Flex>
        </>
    );
};

export const TrainerDocumentsV2 = ({ type }) => {
    const { getValues } = useFormContext();
    const Http = useHttp();

    const handleRemoveDocument = (url) => {
        Http.delete('trainer-event-document', { data: { url: url } }).then((res) => {
            window.location.reload();
        });
    };

    const docData = useMemo(() => getValues(type)?.map(
        (doc) => ({
            name: doc.name,
            created_at: doc?.created_at ?? doc?.document[0]?.created_at,
            url: doc?.document && doc?.document[0]?.url,
        })
    ), [type, getValues])

    return (
        <>
            <Flex
                mt="25px"
                w="100%"
                direction="column"
                bg="white"
                rounded="md"
                p="15px"
                align="flex-start"
            >
                <Table size='sm'>
                    <Thead mb="5px" size='m'>
                        <Tr as="tr">
                            {[`FILE NAME`, `CREATED AT`, `VIEW`, `DELETE`].map((column, i) => (

                                <Th
                                    key={`headerCol-${i}`}
                                    _hover={{ cursor: 'default' }}
                                    style={{
                                        position: 'sticky',
                                        zIndex: 10,
                                    }}
                                    borderColor="gray.200"
                                >
                                    <Flex
                                        align="center"
                                        justify="flex-start"
                                        w="100%"
                                        textAlign="left"
                                    >
                                        {column}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {docData?.map((row, i) => (
                            <Tr
                                bg={i % 2 === 0 ? '#edf2f7' : null}
                                _hover={{ bg: 'tables.rowHoverBg' }}
                                as="tr"
                            >
                                <Td key={`cell-${i}-1`} as="td" borderColor="gray.200">
                                    {
                                        row?.url
                                            ? <a target="_blank" href={`${row?.url}`} rel="noreferrer">
                                                <Button variant="link" colorScheme="blue" rounded="md">
                                                    <Text mr="10px">{row.name}</Text>
                                                </Button>
                                            </a>
                                            : <Text mr="10px">{row.name}</Text>}
                                </Td>
                                <Td key={`cell-${i}-2`} as="td" borderColor="gray.200">
                                    {dayjs(row.created_at).format('DD/MM/YYYY')}
                                </Td>
                                <Td key={`cell-${i}-3`} as="td" borderColor="gray.200">
                                    {
                                        row?.url
                                            ? <a target="_blank" href={`${row?.url}`} rel="noreferrer">
                                                <Button variant="link" colorScheme="blue" rounded="md">
                                                    <FiExternalLink />
                                                </Button>
                                            </a>
                                            : <Text mr="10px"><i>Missing</i></Text>
                                    }
                                </Td>
                                <Td key={`cell-${i}-4`} as="td" borderColor="gray.200">
                                    {row?.url
                                        ? <PopConfirm
                                            onConfirm={() => handleRemoveDocument(row?.url)}
                                            title="Are you sure you want to remove document?"
                                        >
                                            <Button variant="link" colorScheme="red" rounded="md">
                                                <AiFillDelete />
                                            </Button>
                                        </PopConfirm>
                                        : <Text mr="10px"><i>Missing</i></Text>
                                    }
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Flex>
        </>
    );
};

export default TrainerDocuments;
